<template>
<div id="channel_price_upd_log">
    <!-- 通道价格修改记录 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>通道价格修改记录</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <template v-for="(item,index) in tableData">
                    <!-- 卡片 shadow="hover"鼠标悬浮时显示卡片阴影 -->
                    <el-card class="box-card" shadow="hover" style="margin-top:10px;">
                        <div slot="header" class="clearfix">
                            <span>ID:{{ index+1 }}</span>
                            <div style="float: right;margin-right:10px;">{{ item.createtime }}</div>
                        </div>
                        <div class="card_div_class">
                            运营人员：{{ item.qxtname }}
                        </div>
                        <div class="card_div_class">
                            修改前内容：{{ item.before_remark }}
                        </div>
                        <div class="card_div_class" v-if="item.notice_type != -1">
                           修改后内容：{{ item.after_remark }}
                        </div>
                       
                    </el-card>
                </template>
                                    
                


            </el-row>
            

           
            

        </el-row>


    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import {dateFtt} from '../../api/tool.js';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_channel_id:""
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 

                setTimeout(() => {
                    //获取数据
                    this.getData();
                }, 1);

            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度


            tableData:[],//数据
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },
        //查询
        getData(){
            //请求接口
            API.ChannelPriceServlet({
                param: "channelPriceLogs",
                channel_id:this.prop_channel_id,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.tableData = res.logList;
                }
            });
        },
        

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/* 卡片 */
#user_price_upd_log>>>.el-card__header{
    padding: 8px 20px;
}
</style>