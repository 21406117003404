<template>
<div>
    <!-- 通道管理》通道详情》通道屏蔽号段》通道屏蔽号段 增加 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" destroy-on-close :show-close="false" width="98%" style="height:40%" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>增加通道屏蔽号段</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                <el-form  :model="form_data" label-width="150px" style="margin-bottom: 12px;">
                    <el-form-item label="屏蔽号段">
                        <el-input v-model="form_data.section" maxlength="4" 
                                placeholder="请输入屏蔽号段" size="mini"
                                oninput="value=value.replace(/[^\d]/g,'')"></el-input>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                    </el-form-item>
                </el-form>
            </el-row>
        </el-row>
    </el-dialog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';

//import axios from "axios";
export default {
    props:{
        prop_change_i:0,
        prop_channel_id:0,//通道id
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                 setTimeout(() => {
                
                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度


            form_data:{
                section:"",//屏蔽号段
            }
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },


        //提交
        onSubmit(){
            console.log("提交");

            if(API.isEmtry(this.form_data.section)){
                this.$message.error("请填写屏蔽号段");
                return;
            }
            
            API.ChannelServlet({
                param:'stopSectionPhoneAdd',
                channel_id:this.prop_channel_id,
                section:this.form_data.section,
            }).then((res) => {
                if (res.recode === 0) {
                    this.$message.success("恭喜您，信息保存成功");
                    //关闭
                    this.back();
                    //回调
                    this.$emit("result",'');
                }
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>