import { render, staticRenderFns } from "./channel_current_return_state.vue?vue&type=template&id=423b159f&scoped=true&"
import script from "./channel_current_return_state.vue?vue&type=script&lang=js&"
export * from "./channel_current_return_state.vue?vue&type=script&lang=js&"
import style0 from "./channel_current_return_state.vue?vue&type=style&index=0&id=423b159f&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "423b159f",
  null
  
)

export default component.exports