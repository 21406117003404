<template>
<div id="channel_used">
    <!-- 通道管理》通道详情》使用本通道的客户 -->
    <el-dialog class="cur_dialog" title="使用本通道的客户" center :visible.sync="dialog_visible" width="98%" destroy-on-close :show-close="true" :modal-append-to-body="false">
        
         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
                
            <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini" >
                <el-table-column  fixed  prop="cpid" label="cpid" min-width="80"  align="center" sortable> </el-table-column>
                <el-table-column  fixed  prop="company_name" label="公司名" min-width="100"  align="center" sortable> </el-table-column>
            </el-table>
                                    
            
        </el-row>
    </el-dialog>

</div>
</template>
<script>
import API from '../../api/api';

export default {
    props:{
        prop_change_i:0,
        prop_channel_id:""
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                setTimeout(() => {
                    //获取数据
                    this.getData();
                }, 1);
            }

            
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            tableData:[],//表格数据
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 120);
        },
         //数据清除
        dataClear(){
            this.tableData = this.$options.data().tableData;//单个恢复初始化
        },

        //查询
        getData(){
            var channel_id = this.prop_channel_id;
            //请求接口
            API.ChannelServlet({
                param: "channelUserList",
                channel_id:channel_id,
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;
                } 
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
/* .cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
} */

</style>