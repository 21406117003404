<template>
<div id="channel_price_upd_log">
    <!-- 通道管理》通道详情》通道屏蔽号段 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>通道屏蔽号段</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            <div class="dialog_top_close_button" style="margin-top: 0px;">  
                <el-button  type="primary" @click="add()" size="mini">添加</el-button>
            </div>
            
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                
                 <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini" >
                    <el-table-column  fixed  prop="section" label="号段" min-width="80"  align="center" sortable> </el-table-column>
                    <el-table-column  fixed  prop="createtime" label="时间" min-width="100"  align="center" sortable> </el-table-column>
                    <el-table-column   label="操作"  min-width="100" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" style="color:#f56c6c"  @click="del(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>


                </el-table>
                
                
                                    
            </el-row>
            
        </el-row>
    </el-dialog>

    <!-- 通道屏蔽号段增加组件 -->
    <ChannelStopSectionAdd :prop_change_i="channel_stop_section_add_i"
        :prop_channel_id="prop_channel_id"
        @result="getData"
    ></ChannelStopSectionAdd>
</div>
</template>
<script>
import API from '../../api/api';
import ChannelStopSectionAdd from './channel_stop_section_add.vue';//组件 通道屏蔽号段增加

export default {
    components: {
        ChannelStopSectionAdd
    },
    props:{
        prop_change_i:0,
        prop_channel_id:""
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                setTimeout(() => {
                    //获取数据
                    this.getData();
                }, 1);
            }

            
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度


            tableData:[],//表格数据

            //增加
            channel_stop_section_add_i:0,//组件 弹出框是否可见

           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //数据清除
        this.dataClear();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 120);
            
        },
         //数据清除
        dataClear(){
            this.tableData = this.$options.data().tableData;//单个恢复初始化
            this.channel_stop_section_add_i = this.$options.data().channel_stop_section_add_i;//单个恢复初始化
        },
        //增加
        add(){
            this.channel_stop_section_add_i++;//组件 弹出框是否可见
        },
        //删除
        del(id){
            this.$confirm('此操作将永久删除该屏蔽号段, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                    //请求接口
                    API.ChannelServlet({
                        param: "stopSectionPhoneDel",
                        id:id,
                    }).then((res) => {
                        if (res.recode === 0) {
                            this.$message({showClose: true,message: '恭喜你，屏蔽号段删除成功', type: 'success'});
                            //重新加载数据
                            this.getData();
                        }
                    });
            }).catch(() => {//已取消删除
            });
        },

        //查询
        getData(){
            var channel_id = this.prop_channel_id;
            //请求接口
            API.ChannelServlet({
                param: "stopSectionPhoneList",
                channel_id:channel_id,
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;
                } 
            });
        },


        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

</style>