<template>
<div>
    <!-- 通道价格 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>通道价格</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

            <!-- 查询条件 -->
            <el-row class="cursor" style="margin:10px 0px;">
                <el-form  :model="form_data" :inline="true" label-width="60px" style="margin-bottom: 12px;">
                    <el-form-item label="日期">
                        <el-date-picker
                            v-model="form_data.month"
                            type="month"
                            placeholder="选择月" size="mini" style="width:150px;">
                        </el-date-picker>
                    </el-form-item>

                    <el-form-item>
                        <el-button type="primary" @click="getData()" size="mini">查询</el-button>
                    </el-form-item>
                </el-form>
            </el-row>

            <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                
                <!-- 图表 -->
                <div id="image_table_id" style="width: 100%;height:400px;margin-top:0px;">
                </div>                       
                <el-row>
                   <el-button type="primary" @click="price_upd()" size="mini" style="width:100%">价格修改</el-button>     
                </el-row>
                
                <el-row style="margin-top:20px;">
                    <el-button type="primary" @click="price_upd_log()" size="mini" style="width:100%">价格修改日志</el-button>
                </el-row>
                

            </el-row>
            

        </el-row>

    </el-dialog>
    <!-- 通道价格修改组件 -->
    <ChannelPriceUpd :prop_change_i="prop_channel_price_upd_i" :prop_item="prop_channel_price_upd_item" @result="getData"></ChannelPriceUpd>

    <!-- 通道价格修改日志组件 -->
    <ChannelPriceUpdLog :prop_change_i="prop_channel_price_upd_log_i" :prop_channel_id="prop_id"></ChannelPriceUpdLog>
</div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import API from '../../api/api';
import {dateFtt} from '../../api/tool.js';
import * as echarts from 'echarts';
import ChannelPriceUpd from '../channel/channel_price_upd.vue';//组件 通道价格修改
import ChannelPriceUpdLog from '../channel/channel_price_upd_log.vue';//组件 通道价格修改日志

//import axios from "axios";
export default {
    components: {
       ChannelPriceUpd,
       ChannelPriceUpdLog
    },

    props:{
        prop_change_i:0,
        prop_id:"",//通道id
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 

                setTimeout(() => {
                    //获取数据
                    this.getData();
                }, 1);
            }
        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度


            form_data:{
                month:new Date(),//时间月
            },

            //通道价格修改
            prop_channel_price_upd_i:0,//弹出框是否可见
            prop_channel_price_upd_item:{//价格对象
                channel_id:"",//通道id
                CM_price:"",//移动价格 单位分
                CU_price:"",//联通价格 单位分
                CT_price:"",//电信价格 单位分
            },

            //通道价格修改日志
            prop_channel_price_upd_log_i:0,//弹出框是否可见
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 180);
            
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
            this.prop_channel_price_upd_i = this.$options.data().prop_channel_price_upd_i;//单个恢复初始化
            this.prop_channel_price_upd_log_i = this.$options.data().prop_channel_price_upd_log_i;//单个恢复初始化
        },
        //查询
        getData(){
            if(API.isEmtry(this.form_data.month)){
                this.$message.error("请填写日期");
                return;
            }
            var month = dateFtt(this.form_data.month,"yyyy-MM");

           //请求接口
            API.ChannelPriceServlet({
                param: "channelPrice",
                channel_id:this.prop_id,
                month:month,

            }).then((res) => {
                if (res.recode === 0) {


                    var channelPrice = res.channelPrice;
                    for(var i = 0;i<channelPrice.length;i++){
                        var obj = channelPrice[i];
                        var oper = obj.oper;
                        var price = obj.price;
                        var hide_price = obj.hide_price;

                        if(oper == 0){
                            this.prop_channel_price_upd_item.CM_price = price;
                        }else if(oper == 1){
                            this.prop_channel_price_upd_item.CU_price = price;
                        }else if(oper == 2){
                            this.prop_channel_price_upd_item.CT_price = price;
                        }
                    }
                    if(channelPrice.length == 0){
                        this.prop_channel_price_upd_item.CM_price = "0";
                        this.prop_channel_price_upd_item.CU_price = "0";
                        this.prop_channel_price_upd_item.CT_price = "0";
                    }

                    //图表
                    this.imageTable(res.dayList,res.dataList,res.titleList);

                }
            });
        },

        //图表
        imageTable(xAxisData,dataList,titleList){
            var id = "image_table_id";

            // var xAxisData = [];
            // var yAxisData = [];
            // for(var i = 0 ;i<dataList.length;i++){
            //     var dataObj = dataList[i];
                
            //     xAxisData.push(dataObj.daytime);
            //     yAxisData.push(dataObj.success_num);
            // }
            console.log(xAxisData);
            // console.log(yAxisData);
            var series_array = [];
            for(var i = 0;i<dataList.length;i++){
                var val = dataList[i];
                var series_obj = {
                    // itemStyle: {
                    //     color: '#67C23A'//线条颜色
                    // },
                    smooth: true, //线条平滑
                    type:'line'
                };
                series_obj.name = titleList[i];
                series_obj.data = val;
                series_array.push(series_obj);
            }

            var option = {
                //位置调整
                // grid:{
                //     left: '75',
                //     top: '15',//15
                //     right: '35',
                //     bottom: '30'
                // },
                title: {
                    // text: '价格列表'
                },
                tooltip : {
                    trigger: 'axis'
                },
                legend: {
                    data:titleList
//                        data:['总发送量','dsadsa','成功','dsadsadas']
                },
                toolbox: {
                    show : true,
                    feature : {
                        saveAsImage : {show: true}
                    }
                },
                // calculable : true,
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    // data: ['2021-12-01', '2021-12-02', '2021-12-03', '2021-12-04', '2021-12-05', '2021-12-06', '2021-12-07'],
                    data : xAxisData,
                    // axisLabel: {
                    //     color: "#909399",  //坐标轴线的字体颜色
                    //     fontSize: "12",   //坐标轴线的字体大小
                    // },
                    // axisLine:{ 
                    //     lineStyle:{  
                    //         color: "#67C23A",  //坐标轴线的颜色
                    //         width: "1",
                    //         type: "solid",
                            
                    //     },
                    // },
                },
                yAxis: {
                    type: 'value',
                    // axisLabel: {
                    //     formatter: '{value} '
                    // },
                    // axisLabel: {
                    //     color: "#909399",  //刻度线标签的字体颜色
                    //     fontSize: "14",   //坐标轴线的字体大小
                    // },
                    // axisLine:{ 
                    //     lineStyle:{  
                    //         color: "#05f9f9",  //坐标轴线的颜色
                    //         width: "1",
                    //         type: "solid",
                            
                    //     },
                    // },
                },
                series: series_array
            };  
            var chartDom = document.getElementById(id);
            var myChart = echarts.init(chartDom);
            myChart.setOption(option, true);
        },
        

        //价格修改
        price_upd(){
            if(API.isEmtry(this.prop_channel_price_upd_item.CM_price)){
                this.$message.info("请稍后，数据加载中");
            }else{
                this.prop_channel_price_upd_item.channel_id = this.prop_id;
                //通道价格修改
                this.prop_channel_price_upd_i ++;//弹出框是否可见
            }
        },

        //价格修改日志
        price_upd_log(){
            if(API.isEmtry(this.prop_channel_price_upd_item.CM_price)){
                this.$message.info("请稍后，数据加载中");
            }else{
                //通道价格修改日志
                this.prop_channel_price_upd_log_i ++;//弹出框是否可见
            }
        },


        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>