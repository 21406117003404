<template>
<!-- 通道管理》通道配置》通道详情页 -->
    <div id="app_channel_detail">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add()" size="mini">
                    增加
                </el-button>
            </div>
            <div class="head_top_title">通道详情</div>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 0px;background-color: white;">
            <el-row style="overflow: auto;" :style="{height: (content_heigth+'px')}">

                <el-row class="dialog_top cursor" @click.native="show_base = !show_base" style="text-align: left;padding-left:15px;">
                    <span>基本信息</span>
                    <i class="dialog_top_close_button" :class="show_base ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                        style="font-size:24px"></i>
                </el-row>
                <!-- 基本信息 -->
                <el-table v-if="show_base" :data="tableData_base" :show-header="false"  style="width: 100%" size="mini" >
                    <el-table-column  fixed  prop="label"  width="140"  align="center"> </el-table-column>
                    <!-- <el-table-column  prop="show_value"  min-width="100" align="right"> </el-table-column> -->
                    <!-- <el-table-column   label="操作"  min-width="100" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini"  @click="detail(scope.cpid)">客户详情</el-button>
                        </template>
                    </el-table-column> -->

                    <el-table-column  min-width="100" align="right">
                        <template slot-scope="scope">
                            <template v-if="scope.row.name == 'send_citys_array'">
                                <span v-html="scope.row.show_value"></span>
                            </template>
                            <template v-else>
                                {{scope.row.show_value}}
                            </template>
                        </template>
                    </el-table-column>

                </el-table>
                <el-row v-show="show_base" class="dialog_top cursor" @click.native="show_agreement = !show_agreement"
                 style="text-align: left;padding-left:15px;background: #ffffff;font-size: 14px;height: 33px;line-height:33px;">
                    <span>协议</span>
                    <i class="dialog_top_close_button" :class="show_agreement ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                        style="font-size:18px"></i>
                </el-row>
                <!-- 协议属性 -->
                <el-table v-if="show_agreement && show_base" :data="tableData_agreement" :show-header="false"  style="width: 100%"  size="mini" >
                    <el-table-column  fixed  prop="label"  width="140"  align="center"> </el-table-column>
                    <el-table-column  min-width="100" align="right">
                        <template slot-scope="scope">
                            <template v-if="scope.row.name == 'channel_select_type' && detail_obj.channel_select_type == 0">
                                <span>{{scope.row.show_value}}</span>
                                <el-button type="text" size="mini"   style="margin-left:10px;">2</el-button>
                            </template>
                            <template v-else>
                                {{scope.row.show_value}}
                            </template>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 修改按钮 -->
                <el-row v-show="show_base" type="flex" justify="center" class="cursor" style="text-align: left;margin:10px 0px;">
                     <el-button type="primary" size="mini" style="padding:10px 50px;" @click="upd()">修改</el-button>
                </el-row>

                

                

                <!-- 相关配置 -->
                <el-row class="dialog_top cursor" @click.native="show_set = !show_set" style="text-align: left;padding-left:15px;">
                    <span>相关配置</span>
                    <i class="dialog_top_close_button" :class="show_set ? 'el-icon-arrow-down' : 'el-icon-arrow-right'"
                        style="font-size:24px"></i>
                </el-row>

                <!-- 通道价格 -->
                <el-row v-show="show_set" class="dialog_top cursor" @click.native="channel_price()"
                 style="text-align: left;padding-left:15px;background: #ffffff;font-size: 14px;height: 33px;line-height:33px;">
                    <span>通道价格</span>
                    <i class="dialog_top_close_button el-icon-arrow-right" style="font-size:18px"></i>
                </el-row>

                <!-- 屏蔽号段 -->
                <el-row v-show="show_set" class="dialog_top cursor" @click.native="channelStopSection()"
                 style="text-align: left;padding-left:15px;background: #ffffff;font-size: 14px;height: 33px;line-height:33px;">
                    <span>屏蔽号段</span>
                    <i class="dialog_top_close_button el-icon-arrow-right" style="font-size:18px"></i>
                </el-row>

                <!-- 当日返回状态 -->
                <el-row v-show="show_set" class="dialog_top cursor" @click.native="prop_channel_current_return_state_i ++"
                 style="text-align: left;padding-left:15px;background: #ffffff;font-size: 14px;height: 33px;line-height:33px;">
                    <span>当日返回状态</span>
                    <i class="dialog_top_close_button el-icon-arrow-right" style="font-size:18px"></i>
                </el-row>

                <!-- 使用本通道的客户 -->
                <el-row v-show="show_set" class="dialog_top cursor" @click.native="prop_channel_used_i ++"
                 style="text-align: left;padding-left:15px;background: #ffffff;font-size: 14px;height: 33px;line-height:33px;">
                    <span>使用本通道的客户</span>
                    <i class="dialog_top_close_button el-icon-arrow-right" style="font-size:18px"></i>
                </el-row>

                <!-- 通道网关日志  -->
                <el-row v-show="show_set" class="dialog_top cursor" @click.native="prop_channel_network_log_i ++"
                 style="text-align: left;padding-left:15px;background: #ffffff;font-size: 14px;height: 33px;line-height:33px;">
                    <span>通道网关日志 </span>
                    <i class="dialog_top_close_button el-icon-arrow-right" style="font-size:18px"></i>
                </el-row>



            </el-row>
        </el-row>
        
        <!-- 客通道增加或修改组件 -->
        <ChannelAddUpd :prop_change_i="prop_add_upd_base_i"
            :prop_item="detail_obj"
            @result="addUpdResult"
        ></ChannelAddUpd>


        <!-- 通道价格组件 -->
        <ChannelPriceList :prop_change_i="prop_channel_price_i" :prop_id="prop_channel_price_id"></ChannelPriceList>

        <!-- 通道屏蔽号段组件 -->
        <ChannelStopSectionList :prop_change_i="prop_channel_stop_section_i" :prop_channel_id="prop_channel_stop_section_id"></ChannelStopSectionList>

        <!-- 通道当日返回状态组件 -->
        <ChannelCurrentReturnState :prop_change_i="prop_channel_current_return_state_i" :prop_channel_id="channe_id"></ChannelCurrentReturnState>

        <!-- 使用本通道的账户组件 -->
        <ChannelUsed :prop_change_i="prop_channel_used_i" :prop_channel_id="channe_id"></ChannelUsed>

        <!-- 通道网关日志组件 -->
        <ChannelNetworkLog :prop_change_i="prop_channel_network_log_i" :prop_channel_id="channe_id"></ChannelNetworkLog>

        
    </div>
</template>
<script>
//import { defineComponent } from '@vue/composition-api'
import ChannelAddUpd from '../channel/channel_add_upd.vue';//组件 通道增加或修改
import ChannelPriceList from '../channel/channel_price_list.vue';//组件 通道价格
import ChannelStopSectionList from '../channel/channel_stop_section_list.vue';//组件 通道屏蔽号段
import ChannelCurrentReturnState from '../channel/channel_current_return_state.vue';//组件 通道当日返回状态
import ChannelUsed from '../channel/channel_used.vue';//组件 使用本通道的账户
import ChannelNetworkLog from '../channel/channel_network_log.vue';//组件  通道网关日志
import API from '../../api/api';

//import axios from "axios";
export default {
    components: {
       ChannelAddUpd,
       ChannelPriceList,
       ChannelStopSectionList,
       ChannelCurrentReturnState,
       ChannelUsed,
       ChannelNetworkLog
    },

    data(){
        return{
            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            channe_id : 0,//通道id

            detail_obj : {},//客户详情的对象  通过请求接口获取

            show_base:true,//通道基本信息 是否展示  true:展示
            show_agreement:false,//通道协议信息 是否展示  true:展示
            tableData_base:[],//通道基本信息数据
            tableData_agreement:[],//通道协议数据

            show_set:true,//相关配置  是否展示  true:展示


            //通道增加或修改
            prop_add_upd_base_i:0,//弹出框是否可见


            //通道价格
            prop_channel_price_i:0,//弹出框是否可见
            prop_channel_price_id:"",//通道id

            //通道屏蔽号段
            prop_channel_stop_section_i:0,//弹出框是否可见
            prop_channel_stop_section_id:0,//通道id

            //通道当日返回状态
            prop_channel_current_return_state_i:0,//弹出框是否可见

            //使用本通道的客户
            prop_channel_used_i:0,//弹出框是否可见

            //通道网关日志 
            prop_channel_network_log_i:0,//弹出框是否可见

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {

        //重置所有数据
        Object.assign(this.$data, this.$options.data.call(this));
        //初始化设置
        this.initSetUp();
        
        this.channe_id = this.$route.params.id;
        //获取数据
        this.getData();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
        },


        //增加
        add(){
            console.log("增加");
            if(!API.isEmtry(this.detail_obj.channel_id)){
                this.detail_obj.type = "add";
                this.prop_add_upd_base_i ++;//弹出框是否可见
            }

        },
        //修改
        upd(){
            console.log("高级属性修改");
            if(!API.isEmtry(this.detail_obj.channel_id)){
                this.detail_obj.type = "upd";
                this.prop_add_upd_base_i ++;//弹出框是否可见
            }
        },
        //增加或修改结果
        addUpdResult(){
            this.getData();
        },

        //通道价格
        channel_price(){
            console.log("通道价格");
            this.prop_channel_price_id = this.$route.params.id;//通道id
            this.prop_channel_price_i ++;//弹出框是否可见
        },

        //通道屏蔽号段
        channelStopSection(){
            console.log("通道屏蔽号段");
            this.prop_channel_stop_section_id = this.$route.params.id;//通道id
            this.prop_channel_stop_section_i ++;//弹出框是否可见
        },


        //获取数据
        getData(){
            this.tableData_base = this.$options.data().tableData_base;//单个恢复初始化
            this.tableData_agreement = this.$options.data().tableData_agreement;//单个恢复初始化

            var id = this.$route.params.id;
            //请求接口
            API.ChannelServlet({
                param: "detail",
                channel_id:id,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {

                    this.detail_obj = res.detail;
                    var show_value = "";

                    var table_obj = {label:"通道ID",show_value:res.detail.channel_id,name:"channel_id"};
                    this.tableData_base.push(table_obj);

                    if(1 == 1){
                        
                        table_obj = {label:"通道名",show_value:res.detail.channel_name,name:"channel_name"};
                        this.tableData_base.push(table_obj);

                        show_value = "";
                        var opers = res.detail.oper.split(",");
                        for (let oper of opers) {
                            if(show_value != ""){
                                show_value += ",";
                            }
                            if(oper == "0"){
                                show_value += "移动";
                            }else if(oper == "1"){
                                show_value += "联通";
                            }else if(oper == "2"){
                                show_value += "电信";
                            }
                        }
                        table_obj = {label:"可发运营商",show_value:show_value,name:""};
                        this.tableData_base.push(table_obj);

                        if(opers.indexOf("0") > -1){
                            table_obj = {label:"移动可发省份",show_value:res.detail.cm_province_str,name:""};
                            this.tableData_base.push(table_obj);

                            if(res.detail.cm_send_citys_array.length > 0){
                                table_obj = {label:"移动可发地市",show_value:res.detail.cm_send_citys_array_str,value:res.detail.cm_send_citys_array,name:"send_citys_array"};
                                this.tableData_base.push(table_obj);
                            }
                        }
                        if(opers.indexOf("1") > -1){
                            table_obj = {label:"联通可发省份",show_value:res.detail.cu_province_str,name:""};
                            this.tableData_base.push(table_obj);

                            if(res.detail.cu_send_citys_array.length > 0){
                                table_obj = {label:"联通可发地市",show_value:res.detail.cu_send_citys_array_str,value:res.detail.cu_send_citys_array,name:"send_citys_array"};
                                this.tableData_base.push(table_obj);
                            }
                        }
                        if(opers.indexOf("2") > -1){
                            table_obj = {label:"电信可发省份",show_value:res.detail.ct_province_str,name:""};
                            this.tableData_base.push(table_obj);

                            if(res.detail.ct_send_citys_array.length > 0){
                                table_obj = {label:"电信可发地市",show_value:res.detail.ct_send_citys_array_str,value:res.detail.ct_send_citys_array,name:"send_citys_array"};
                                this.tableData_base.push(table_obj);
                            }

                        }

                        show_value = "";
                        if(res.detail.channel_style == 0){
                            show_value = "短信通道";
                        }else if(res.detail.channel_style == 1){
                            show_value = "语音通道";
                        }else if(res.detail.channel_style == 2){
                            show_value = "多媒体短信通道";
                        }else if(res.detail.channel_style == 3){
                            show_value = "固话通道";
                        }

                        table_obj = {label:"通道类型",show_value:show_value,name:""};
                        this.tableData_base.push(table_obj);
                        if(res.detail.channel_style == 1){
                            table_obj = {label:"语音价格",show_value:res.detail.channel_voice_str,name:""};
                            this.tableData_base.push(table_obj);
                        }

                        table_obj = {label:"当日已发送条数",show_value:res.detail.moNum+ '条',name:""};
                        this.tableData_base.push(table_obj);

                        

                        table_obj = {label:"服务号码",show_value:res.detail.specialnum,name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"短信数据统计",show_value:res.detail.alarm == 0 ? '不预警' : '预警',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"签名位置",show_value:res.detail.sign_position == 0 ? '强制前置' : res.detail.sign_position == 1 ? '强制后置' :'不管签名位置',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"签名库",show_value:res.detail.sign_name,name:""};
                        this.tableData_base.push(table_obj);
                        if(res.detail.sign_library_id > 0){
                            table_obj = {label:"签名扩展类型",show_value:res.detail.sign_ext_onoff == 0 ? '使用签名库扩展' : res.detail.sign_position == 1 ? '使用签名库扩展+客户扩展' :'使用客户扩展',name:""};
                            this.tableData_base.push(table_obj);
                            table_obj = {label:"后报备签名",show_value:res.detail.sign_after == 0 ? '允许' : '不允许',name:""};
                            this.tableData_base.push(table_obj);
                        }

                        table_obj = {label:"通道状态",show_value:res.detail.onoff == 0 ? '关闭' : '打开',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"是否支持携号转网",show_value:res.detail.change_oper_onoff == 0 ? '不支持' : '支持',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"实时空号过滤",show_value:res.detail.phone_check == 0 ? '打开' : '关闭',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"是否信任",show_value:res.detail.trust_onoff == 0 ? '不信任' : '信任',name:""};
                        this.tableData_base.push(table_obj);

                        show_value = "";
                        var send_types = res.detail.send_type.split(",");
                        for (let v of send_types) {
                            if(show_value != ""){
                                show_value += ",";
                            }
                            if(v == "1"){
                                show_value += "验证码";
                            }else if(v == "2"){
                                show_value += "行业";
                            }else if(v == "3"){
                                show_value += "营销";
                            }else if(v == "4"){
                                show_value += "四类";
                            }
                        }
                        table_obj = {label:"下发短信类型",show_value:show_value,name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"是否允许带链接",show_value:res.detail.link_is == 0 ? '允许' : '不允许',name:""};
                        this.tableData_base.push(table_obj);
                        if(res.detail.link_is == 0 ){
                            table_obj = {label:"带链接可发级别",show_value:res.detail.link_trust_level == 0 ? '可发信任的客户' : '可发不信任的客户',name:""};
                            this.tableData_base.push(table_obj);
                        }

                        table_obj = {label:"是否支持长短信",show_value:res.detail.isLong == 0 ? '支持' : '不支持',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"是否支持上行",show_value:res.detail.isMo == 0 ? '支持' : '不支持',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"支持的最大扩展位数",show_value:res.detail.ext_max,name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"支持的特殊标签",show_value:res.detail.special_label,name:""};
                        this.tableData_base.push(table_obj);

                        if(res.detail.special_label != ""){
                            table_obj = {label:"特殊标签的作用范围",show_value:res.detail.special_label_type == 0 ? '可发此特殊标签' : '仅能发此特殊标签',name:""};
                            this.tableData_base.push(table_obj);
                        }

                        var supplier_type = res.detail.supplier_type;
                        table_obj = {label:"供应商类别",show_value:supplier_type == 0 ? '直连' : supplier_type == 1 ? '准直连' : supplier_type == 2 ? '三方' : '厂商',name:""};
                        this.tableData_base.push(table_obj);

                        if(res.detail.supplier_type == 3){//厂商
                            table_obj = {label:"厂商类别",show_value:res.detail.firm_type == 0 ? '小米' : 'oppo',name:""};
                            this.tableData_base.push(table_obj);
                        }
                        
                        table_obj = {label:"码号类别",show_value:res.detail.number_type == 0 ? '固定码号' : '通道组',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"通道下发地区类型",show_value:res.detail.all_or_prov == 0 ? '全网' : '省网',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"是否允许带联系方式",show_value:res.detail.is_connect == 0 ? '允许' : '不允许',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"是否支持纯英文",show_value:res.detail.pure_english_onoff == 0 ? '允许' : '不允许',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"是否是自选通道",show_value:res.detail.is_own == 0 ? '是' : '不是',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"活跃性开关",show_value:res.detail.active_onoff == 0 ? '打开' : '关闭',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"预警策略",show_value:res.detail.warning_name == '' ? '不配置' : res.detail.warning_name,name:""};
                        this.tableData_base.push(table_obj);


                        table_obj = {label:"付费类型",show_value:res.detail.channel_fee_type == 0 ? '预付费' : '后付费',name:""};
                        this.tableData_base.push(table_obj);
                        if(res.detail.channel_fee_type == 1){
                            var pay_style = res.detail.pay_style;
                            table_obj = {label:"结算方式",show_value:pay_style == 2 ? '日结' : pay_style == 3 ? '周结' :  pay_style == 4 ? '半月结' : pay_style == 5 ? '月结' : '未知',name:""};
                            this.tableData_base.push(table_obj);
                        }else{
                            table_obj = {label:"通道余额",show_value:res.detail.channel_fee+"元",name:""};
                            this.tableData_base.push(table_obj);
                        }

                        table_obj = {label:"付款公司",show_value:res.detail.payment_company,name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"收款公司",show_value:res.detail.collection_company,name:""};
                        this.tableData_base.push(table_obj);


                        table_obj = {label:"启动路径",show_value:res.detail.path_start,name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"关闭路径",show_value:res.detail.path_shutdown,name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"网关ip",show_value:res.detail.server_ip,name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"网关开关",show_value:res.detail.net_work_onoff == 0 ? '手动关闭' : res.detail.net_work_onoff == 1 ? '打开' : '自动关闭',name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"频次限制",show_value:res.detail.frequency,name:""};
                        this.tableData_base.push(table_obj);

                        table_obj = {label:"发送速度",show_value:res.detail.send_speed+"条/秒",name:""};
                        this.tableData_base.push(table_obj);

                        if(send_types.indexOf("1") > -1){
                            show_value = this.send_hhmm_str(res.detail.BeginTime_1)+"到"+this.send_hhmm_str(res.detail.EndTime_1);
                            table_obj = {label:"可发时段-验证码",show_value:show_value,name:""};
                            this.tableData_base.push(table_obj);
                        }
                        if(send_types.indexOf("2") > -1){
                            show_value = this.send_hhmm_str(res.detail.BeginTime_2)+"到"+this.send_hhmm_str(res.detail.EndTime_2);
                            table_obj = {label:"可发时段-行业",show_value:show_value,name:""};
                            this.tableData_base.push(table_obj);
                        }
                        if(send_types.indexOf("3") > -1){
                            show_value = this.send_hhmm_str(res.detail.BeginTime_3)+"到"+this.send_hhmm_str(res.detail.EndTime_3);
                            table_obj = {label:"可发时段-营销",show_value:show_value,name:""};
                            this.tableData_base.push(table_obj);
                        }
                        if(send_types.indexOf("4") > -1){
                            show_value = this.send_hhmm_str(res.detail.BeginTime_4)+"到"+this.send_hhmm_str(res.detail.EndTime_4);
                            table_obj = {label:"可发时段-四类",show_value:show_value,name:""};
                            this.tableData_base.push(table_obj);
                        }

                        table_obj = {label:"备注",show_value:res.detail.content,name:""};
                        this.tableData_base.push(table_obj);
                        
                    }

                    

                    //------协议-----------------------------------------------------------------------------------------------
                    if(2 == 2){
                        show_value = "";
                        if(res.detail.agreement == 0){
                            show_value = "无协议";
                        }else if(res.detail.agreement == 1){
                            show_value = "HTTP";
                        }else if(res.detail.agreement == 101){
                            show_value = "移动CMPP2.0";
                        }else if(res.detail.agreement == 102){
                            show_value = "移动CMPP3.0";
                        }else if(res.detail.agreement == 104){
                            show_value = "联通SGIP";
                        }else if(res.detail.agreement == 105){
                            show_value = "电信SMGP";
                        }
                        table_obj = {label:"协议类型",show_value:show_value,name:""};
                        this.tableData_agreement.push(table_obj);

                        table_obj = {label:"用户名",show_value:res.detail.net_work_act,name:""};
                        this.tableData_agreement.push(table_obj);

                        table_obj = {label:"密码",show_value:res.detail.net_work_pwd,name:""};
                        this.tableData_agreement.push(table_obj);

                        if(res.detail.agreement == 1){//协议类型,0:无协议；1:http；101:cmpp2.0 ；102:cmpp3.0；104:SGIP；105:SMGP
                            table_obj = {label:"userId",show_value:res.detail.userId,name:""};
                            this.tableData_agreement.push(table_obj);
                        }

                        if(res.detail.agreement != 0 && res.detail.agreement != 1){
                            table_obj = {label:"并发数",show_value:res.detail.concurrent,name:""};
                            this.tableData_agreement.push(table_obj);
                        }

                        table_obj = {label:"扩展码截取顺序",show_value:res.detail.ext_order == 0 ? '从前往后截取' : '从后往前截取',name:""};
                        this.tableData_agreement.push(table_obj);

                        table_obj = {label:"扩展码截取位数",show_value:res.detail.ext_num,name:""};
                        this.tableData_agreement.push(table_obj);

                        table_obj = {label:"去签名",show_value:res.detail.delSign == 0 ? '不需要' : '需要',name:""};
                        this.tableData_agreement.push(table_obj);

                        table_obj = {label:"日志输出",show_value:res.detail.log_Onoff == 0 ? '不需要' : '需要',name:""};
                        this.tableData_agreement.push(table_obj);

                        if(res.detail.agreement != 0 && res.detail.agreement != 1){
                            table_obj = {label:"链接ip",show_value:res.detail.Gateway_IP,name:""};
                            this.tableData_agreement.push(table_obj);

                            table_obj = {label:"企业代码(msg_src)",show_value:res.detail.msg_Src,name:""};
                            this.tableData_agreement.push(table_obj);

                            table_obj = {label:"链接端口",show_value:res.detail.Gateway_Port,name:""};
                            this.tableData_agreement.push(table_obj);

                            table_obj = {label:"发送缓冲",show_value:res.detail.buffer_time+"毫秒/条",name:""};
                            this.tableData_agreement.push(table_obj);

                            table_obj = {label:"心跳包间隔发送时间",show_value:res.detail.heartbeat_interval+"秒",name:""};
                            this.tableData_agreement.push(table_obj);

                            table_obj = {label:"链接运营商超时阈值",show_value:res.detail.reconnect_interval+"秒",name:""};
                            this.tableData_agreement.push(table_obj);

                            table_obj = {label:"心跳包等待最长时间",show_value:res.detail.heartbeat_noresponseout+"秒",name:""};
                            this.tableData_agreement.push(table_obj);

                            table_obj = {label:"业务类型(service_Id)",show_value:res.detail.Service_Id,name:""};
                            this.tableData_agreement.push(table_obj);

                            table_obj = {label:"扩展码(src_id)",show_value:res.detail.SRC_ID,name:""};
                            this.tableData_agreement.push(table_obj);
                        }

                        if(res.detail.agreement == 104){
                            table_obj = {label:"本地监听端口",show_value:res.detail.local_port,name:""};
                            this.tableData_agreement.push(table_obj);

                            table_obj = {label:"本地端口用户名",show_value:res.detail.local_name,name:""};
                            this.tableData_agreement.push(table_obj);

                            table_obj = {label:"本地端口密码",show_value:res.detail.local_passowrd,name:""};
                            this.tableData_agreement.push(table_obj);

                        }

                        

                        


                    }
           
                }
                
            });
        },

        //hhmm转中文
        send_hhmm_str(send_hhmm_){
            var send_hhmm_str = "未知点";

            if(API.isEmtry(send_hhmm_)){
                return send_hhmm_str;
            }
            var send_hhmm = send_hhmm_ + "";
            if(send_hhmm.length == 1){
                send_hhmm_str = "0点";
            }else if(send_hhmm.length == 2){
                send_hhmm_str = "0点半";
            }else if(send_hhmm.length == 3){
                var send_hhmm_end_2 = send_hhmm.substring(1);
                if(send_hhmm_end_2 == '00'){
                    send_hhmm_str = send_hhmm.substring(0,1)+"点";
                }else{
                    send_hhmm_str = send_hhmm.substring(0,1)+"点半";
                }
            }else if(send_hhmm.length == 4){
                var send_hhmm_end_2 = send_hhmm.substring(2);
                if(send_hhmm_end_2 == '00'){
                    send_hhmm_str = send_hhmm.substring(0,2)+"点";
                }else{
                    send_hhmm_str = send_hhmm.substring(0,2)+"点半";
                }
            }
            return send_hhmm_str;
        },
        //返回
        goBack() {
            API.router_to("/channel_list");
        }
    }
};

</script>

<style scoped>

</style>