<template>
<div id="channel_network_log">
    <!-- 通道管理》通道详情》通道网关日志 -->
    <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen :destroy-on-close="false" :show-close="false" :modal-append-to-body="false">
        <!-- 头 -->
        <el-row class="dialog_top">
            <span>通道网关日志</span>
            <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
        </el-row>

         <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            <el-form  :model="form_data" label-width="128px" style="margin-bottom: 12px;">
                <el-tabs v-model="activeName" >
                    <el-tab-pane label="详情日志" name="first">
                        <el-form-item label="日期">
                            <el-date-picker
                                v-model="form_data.daytime"
                                type="date"
                                placeholder="选择日期" size="mini" style="width:130px;">
                            </el-date-picker>
                        </el-form-item>

                        <el-form-item label="手机号">
                            <el-input v-model="form_data.phone" placeholder="请填写手机号" size="mini"
                                oninput="value=value.replace(/[^\d]/g,'')">
                            </el-input>
                        </el-form-item>

                    </el-tab-pane>
                    <el-tab-pane label="网关断链" name="two">
                        <el-form-item label="日期">
                            <el-date-picker
                                v-model="form_data.daytime"
                                type="date"
                                placeholder="选择日期" size="mini" style="width:130px;">
                            </el-date-picker>
                        </el-form-item>
                    </el-tab-pane>
                    <el-tab-pane label="网关链接" name="three">
                        <el-form-item label="日期">
                            <el-date-picker
                                v-model="form_data.daytime"
                                type="date"
                                placeholder="选择日期" size="mini" style="width:130px;">
                            </el-date-picker>
                        </el-form-item>
                    </el-tab-pane>
                </el-tabs>
                <el-form-item>
                    <el-button type="primary" @click="getData()" size="mini">查询</el-button>
                </el-form-item>
            </el-form>
        </el-row>
    </el-dialog>

    <el-dialog class="cur_dialog" title="" :visible.sync="dialog_visible_log"  fullscreen destroy-on-close :show-close="true" >
        <el-row class="dialog_top">
            <span>log日志</span>
            <i class="el-icon-close dialog_top_close_button" @click="dialog_visible_log = false" style="font-size:24px"></i>
        </el-row>
        <el-row style="overflow: auto;background-color:#000;color: #fff; padding: 20px;line-height: 24px;" :style="{height: (content_heigth+'px')}">
            <span v-html='exec_result'></span>
            
        </el-row>
    </el-dialog>

</div>
</template>
<script>
import API from '../../api/api';
import {dateFtt} from '../../api/tool.js';

export default {
    
    props:{
        prop_change_i:0,
        prop_channel_id:""
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                setTimeout(() => {
                    //获取数据
                }, 1);
            }

        }
    },

    
    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            dialog_visible_log:false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            activeName: 'first',

            form_data:{
                daytime:new Date(),//日期
                phone:"",//手机号
            },

            exec_result:"test",//查询结果

           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 50);
            
        },
         //数据清除
        dataClear(){
            this.tableData = this.$options.data().tableData;//单个恢复初始化
        },
      

        //查询
        getData(){
            if(API.isEmtry(this.form_data.daytime)){
                this.$message.error("请填写日期");
                return;
            }
            var daytime = dateFtt(this.form_data.daytime,"yyyy-MM-dd");
            if(this.activeName == "first" && API.isEmtry(this.form_data.phone)){
                this.$message.error("请填写手机号");
                return;
            }
            var param = "";
            if(this.activeName == "first"){
                param = "channelNetLogDetail";
            }else if(this.activeName == "two"){
                param = "channelNetLinkBreak";
            }else{
                param = "channelNetLinkNum";
            }

            var channel_id = this.prop_channel_id;
            //请求接口
            API.ChannelServlet({
                param: param,
                channel_id:channel_id,
                datetime:daytime,
                phone:this.form_data.phone
            }).then((res) => {
                if (res.recode === 0) {
                    this.exec_result = res.exec_result;//查询结果
                    this.dialog_visible_log = true;//弹出框是否可见  false:不可见
                } 
            });
        },

        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },


    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}

</style>